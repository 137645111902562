<template>

  <v-app>
    <main-app-bar />
    <main-navigation-drawer v-if="userStore.connected" />
    <v-main>
      <main-banner />
      <v-container
        v-if="loading"
        class="fill-height justify"
        fluid
      >
        <v-row
          :align="'center'"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :size="100"
          >
            <v-img
              :src="logo"
              :height="50"
              cover
            />
          </v-progress-circular>
        </v-row>
      </v-container>
      <router-view
        v-else
        @loading-hide="loadingHide"
      />
    </v-main>
    <glyue-toast ref="gtoast" />
  </v-app>
</template>

<script>
import { mapStores } from 'pinia'
import GlyueToast from './components/GlyueToast.vue'
import MainAppBar from './components/maintemplate/MainAppBar.vue'
import MainNavigationDrawer from './components/maintemplate/MainNavigationDrawer.vue'
import logo from './media/logo.png'
import { useUserStore } from "./stores/user"
import MainBanner from './components/maintemplate/MainBanner.vue'

export default {
  components: {
    GlyueToast,
    MainAppBar,
    MainNavigationDrawer,
    MainBanner,
  },
  data() {
    return {
      loading: true,
      logo: logo,
    }
  },
  computed: {
    ...mapStores(useUserStore, ['connected']),
  },
  watch: {
    '$route.name': function () {
      this.currentPage = this.$route.path
    }
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      if (to.path !== from.path) {
        this.loading = true
      }
      next()
    })
    this.$router.afterEach(() => {
      this.$metrics.route(window.location.pathname)
      this.loadingHide()
    })
  },
  methods: {
    loadingHide() {
      this.loading = false
    },
  },
}
</script>

<style>
/* vuetify forces scrollbar to always show with the css reset package it uses */
/* https://vuetifyjs.com/en/getting-started/frequently-asked-questions/#scrollbar-overflow */
html {
  overflow-y: auto !important;
}

.introjs-tooltip {
  min-width: 350px !important;
  max-width: 400px !important;
}

.glyue-banner {
  position: absolute;
  width: 92%;
  left: 4%;
  top: 2%;
  z-index: 99;
  opacity: 95%;
  background-color: white !important;
}

.dp__theme_light {
  --dp-primary-color: #02b8a6 !important;
}
</style>
